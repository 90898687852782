// 资讯管理
<template>
  <div class="manage page_width">
    <div class="manage_header">
      <page-header
        icon="icondingdanguanli_1"
        label="资讯管理">
      </page-header>
    </div>
    <div class="manage_main tw-flex">
      <div class="manage_main_left">
        <div class="custom_menu">
          <el-menu
            class="my-el-menu-no-border"
            :default-active="$route.meta.sidebar"
            :unique-opened="true"
            :router="true">
            <el-menu-item index="/info-manage/info-stats">
              <p
                class="menu_item_title"
                slot="title">
                资讯统计
              </p>
            </el-menu-item>
            <el-menu-item index="/info-manage/info-list">
              <p
                class="menu_item_title"
                slot="title">
                资讯列表
              </p>
            </el-menu-item>
            <el-menu-item index="/info-manage/info-check">
              <p
                class="menu_item_title"
                slot="title">
                资讯审核
              </p>
            </el-menu-item>
          </el-menu>
        </div>
      </div>
      <div class="manage_main_right tw-flex-1">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.manage {
  margin-bottom: 25px;
  &_header {
    padding: 30px 0 20px 0;
  }
  &_main {
    &_left {
      width: 200px;
      margin-right: 20px;
    }
    &_right {
      width: 980px;
    }
  }
}

.custom_menu {
  padding: 10px 0;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  overflow: hidden;
  .menu_item_title, .sub_menu_item_title {
    position: relative;
    color: #606266;
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      left: -20px;
      width: 4px;
      background-color: transparent;
    }
  }
  .sub_menu_item_title {
    &::before {
      left: -40px;
    }
  }
  .el-menu-item.is-active {
    .menu_item_title, .sub_menu_item_title {
      color: #3473E6;
      font-weight: bold;
      &::before {
        background-color: #3473E6;
      }
    }
  }
}
</style>
